

































































import Vue from "vue";
import { IOrderRequest } from "@/store/types/Order";
import { mapMutations, mapActions } from "vuex";
import i18n from "@/i18n/index";

export default Vue.extend({
  data() {
    return {
      orderNumber: "",
      postcode: "",
      orderNumberValidationMessage: "",
      orderNumberValidationErrors: "",
      postcodeValidationMessage: "",
      postcodeValidationErrors: "",
      error: false,
      validForm: true,
      isLoading: false,
    };
  },
  methods: {
    async handleLogin() {
      this.orderNumber = this.orderNumber.trim();
      this.postcode = this.postcode.trim();
      if (!this.orderNumber || !this.postcode) {
        return false;
      } else {
        this.isLoading = true;
        this.requestOrderDetails({
          externalOrderNumber: this.orderNumber,
          postcode: this.postcode,
          recaptchaToken: (arg: string)=> this.$recaptcha(arg),
          encryptionLoginType: false,
        } as IOrderRequest)
          .then((loginSuccessful) => {
            if (loginSuccessful) {
              this.isLoading = false;
              this.LOGIN();
              this.$navigateTo('order')
            }
            else {
              this.handleLoginError();
            }
          })
          .catch(() => {
            this.handleLoginError();
          });
      }
    },
    handleLoginError() {
      this.isLoading = false;
      const currentLocale = i18n.locale;
      // ENDCP-138 - Custom error message not if pl, for now...
      if (currentLocale === "de") {
        this.SET_CUSTOM_ERROR_MESSAGE(i18n.t("heroLogin.error"));
      }
      this.noResult();
    },
    validateForm() {
      for (const element in this.$refs) {
        if (!this.validateElement(element)) {
          this.validForm = false;
        }
      }
      return this.validForm;
    },
    validateOnBlur(elementName: string) {
      const self = this as any;

      // if everything was ok, there is no need to set/check validation statuses
      if (this.validForm && !this.error) return;

      // we don't want to validate the element before sending, so we just assume the value changed and set the elements validation to "ok"
      this.setValidationStatusForElement(elementName, true, "");

      // Check if every element is now valid
      for (const element in this.$refs) {
        const statusFlagIndex = `${element}ValidationErrors`;
        if (self[statusFlagIndex] !== "ok") {
          // if one element is still invalid, we keep the error
          return;
        }
      }

      this.validForm = true;
      this.error = false;
    },
    noResult() {
      this.error = true;
      this.validForm = false;
    },
    validateElement(elementName: string) {
      const formElement = this.$refs[elementName] as HTMLFormElement;
      const validity = formElement.validity;

      let errorMsg = "";

      if (validity.valueMissing) {
        errorMsg = "validationErrors.invalidRequiredText";
      } else if (validity.patternMismatch) {
        if (elementName === "orderNumber") {
          errorMsg = "validationErrors.invalidOrderNumber";
        } else if (elementName === "postcode") {
          errorMsg = "validationErrors.invalidPostCode";
        }
      }

      const validationOk = errorMsg === "";
      const localizedMessage = validationOk
        ? errorMsg
        : (this.$t(errorMsg) as string);

      this.setValidationStatusForElement(
        elementName,
        validationOk,
        localizedMessage
      );

      return validationOk;
    },
    setValidationStatusForElement(
      elementName: string,
      validationOk: boolean,
      localizedMessage: string
    ) {
      const self = this as any;
      const statusFlagIndex = `${elementName}ValidationErrors`;
      const validationMessageIndex = `${elementName}ValidationMessage`;

      self[statusFlagIndex] = validationOk ? "ok" : "error";
      self[validationMessageIndex] = localizedMessage;
    },
    send() {
      const validForm = this.validateForm();

      if (validForm) {
        this.handleLogin();
      }
    },
    close() {
      this.error = false;
      this.validForm = true;
    },
    ...mapMutations(["LOGIN", "SET_CUSTOM_ERROR_MESSAGE"]),
    ...mapActions(["requestOrderDetails"]),
  },
});
