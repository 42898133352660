














































































































import Vue from "vue";
import Stepper from "@/components/modules/Stepper.vue";
import { mapGetters, mapActions, mapState } from "vuex";
import "@/extensions/StringExtensions.ts";
import moment from "moment";
import i18n from "@/i18n/index";
import { getRouteName } from "@/routeConfig";
import { IBookableService } from "@/store/types/Service";
import EnvironmentService from "@/services/EnvironmentService";
import {legacyApiService} from "@/services/ApiService";
import { featureFlags } from "@/store/feature-flag.store";

export default Vue.extend({
  components: {
    Stepper,
  },
  data() {
    return {
      gtcCheck: false,
      checkboxChecked: false,
      isLoading: false,
      backEndError: false,
      totalCosts: 0,
      additionalServicesPrice: 0,
      gtc: getRouteName("gtc"),
      additionalServicesError: false,
    };
  },
  computed: {
    ...mapGetters(["getSelectedAppointment", "getStepperValue", "getOrder"]),
    ...mapState(["bookedServices", "bookableServices"]),
    requireGtcAcceptance: function (): boolean {
      return this.$i18n.locale === "de";
    },
  },
  mounted() {
    if (!this.requireGtcAcceptance) {
      // Set checkbox to true by default
      this.gtcCheck = true;
      this.checkboxChecked = true;
    }
    for (const service of this.bookedServices) {
      this.totalCosts += service.sumPrice;
    }
    this.additionalServicesPrice = this.totalCosts / 100;

    if (this.getSelectedAppointment.price > 0) {
      this.totalCosts += this.getSelectedAppointment.price;
    }
    this.gtcCheck = this.totalCosts > 0;
  },
  methods: { 
    confirmationBack() {

      if (this.bookableServices.length > 0) {
        this.$navigateTo("additionalServices");
      } else {
        this.$navigateTo("appointmentSelection");
      }
    },

    confirmAppointment() {
    this.isLoading = true;
      this.setCustomerAppointment()
        .then(() => {
          this.reloadOrderDetails().then(() => {
             this.saveAdditionalServices();
          }).catch(() => this.isLoading = false);
        })
        .catch(() => {
          this.backEndError = true;
          this.isLoading = false;
        });
    },
    saveAdditionalServices() {
      // Saves additional services to the order service, without sending an email to customer service
      this.bookServices()
        .then(() => this.$navigateTo("appointmentSummary"))
        .catch(() => {
            this.isLoading = false;
            this.additionalServicesError = true
        });
    },
    saveAdditionalServicesByEmail() {
      // Send an email to customer service, asking them to add selected services
      // to the order in TMS. The order service is not informed about the changes
      this.sendAdditionalServices().then(() => this.$navigateTo("appointmentSummary"));
    },
    async sendAdditionalServices() {
      if (this.bookedServices.length > 0) {
        this.bookedServices.forEach((bookedService: IBookableService) => {
          const mailObject = {
            orderNumber: this.getOrder.externalOrderNumber,
            client: this.getOrder.clientKey,
            bookedServiceKey: bookedService.key,
            date: moment(new Date()).format("DD:MM:YYYY hh:mm"),
            language: i18n.locale,
            articleCount: this.getOrder.articleCount,
            price: bookedService.price
          };

          legacyApiService.post(
            EnvironmentService.applicationConfiguration.additionalServicesMailApiPath,
            mailObject,
            "additionalServicesMail"
          );
        }) 
      }      
    },
    getLinkToGtc() {
      return { name: getRouteName("gtc") };
    },
    ...mapActions([
      "setCustomerAppointment",
      "reloadOrderDetails",
      "bookServices",
    ]),
  },
});
