import { apiService } from "./ApiService";

export class TrackingService {
  public async GetEstimatedTimeOfArrival(
    internalOrderId: string
  ) {
    const response = await apiService.get(
      `SelfScheduling/eta/${internalOrderId}`,
      {},
      "get_eta"
    );

    return response.data;
  }
}

export const trackingService = new TrackingService();