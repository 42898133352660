<template>
  <div class="map" :class="{ 'is-unavailable': !available }">
    <template v-if="available">
      <RhButton
        priority="secondary"
        :value="$t('tracking.LiveTracking')"
        @click="$emit('initialize')"
      />
    </template>
    <div v-else class="map-message">
      <div class="map-title">{{ $t("tracking.Error") }}</div>
      <div class="map-subtitle">{{ $t("tracking.Questions") }}</div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";

export default Vue.extend({
  props: {
    available: {
      type: Boolean,
      default: false,
    },
  },
});
</script>

<style lang="scss" scoped>
$color-map-message: #404146;
$color-map-message-bg: #e6e8e9;

.map {
  position: relative;
  margin-top: 80px;
  width: 100%;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover;
  background-position: center center;
  background-image: url(../../assets/photos/maps-without-labels.png);
  padding: 30px;
  color: $color-map-message;
  margin-left: unset;
  &.is-unavailable {
    &::before {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background-color: $color-map-message;
      opacity: 0.5;
    }
  }

  &-message {
    padding: 15px;
    border: 1px solid $color-map-message;
    background-color: $color-map-message-bg;
    z-index: 1;
  }

  &-title {
    font-weight: bold;
  }
}
</style>
